<script>
import {authMethods} from "@/state/helpers";
import {required, email} from "vuelidate/lib/validators";
import Preference from "@/data/Preference";
import HttpRequest from "@/http/HttpRequest";

import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);

let preference = new Preference()
let httpRequest = new HttpRequest()

export default {
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false
    };
  },
  validations: {
    email: {required, email}
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {

        let _this = this

        // Init loader
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots"

        });

        // Init sweet alert2
        let swal = this.$swal

        _this.storeClientUniqueId()

        let clientID = preference.getData(Preference.KEY_CLIENT_ID)

        httpRequest.workspaceNameRequest(this.email, clientID).then(function (response) {
          loader.hide()
          if (response.success) {
            swal(response.message);
          } else {
            swal(response.message);
          }
        })
      }
    }, storeClientUniqueId() {
      let nav = window.navigator
      let screen = window.screen
      let guid = nav.mimeTypes.length
      guid += nav.userAgent.replace(/\D+/g, '')
      guid += nav.plugins.length
      guid += screen.height || ''
      guid += screen.width || ''
      guid += screen.pixelDepth || ''

      preference.setData(Preference.KEY_CLIENT_ID, guid)
    }
  }
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-5 col-xl-4" style="background-color: #f1f5f7">
            <div
                class="authentication-page-content p-4 d-flex align-items-center flex-column justify-content-center min-vh-100">

              <div class="text-center">
                <div>
                  <a href="/" class="WorkAny">
                    <img src="@/assets/images/workany-v-logo.png" height="130" width="180" alt="WorkAny"/>
                  </a>
                </div>

                <h4 class="font-size-18 mt-4">Forgot your workspace?</h4>
              </div>

              <div class="p-2 mt-3 mt-md-5 w-100" style="max-width: 440px">
                <b-alert
                    v-model="isResetError"
                    class="mb-4"
                    variant="danger"
                    dismissible
                >{{ error }}
                </b-alert>

                <form class="form-horizontal" @submit.prevent="tryToReset">
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-mail-line auti-custom-input-icon"></i>
                    <label for="useremail">Email</label>
                    <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        id="useremail"
                        placeholder="Enter email address"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                    />
                    <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                      <span v-if="!$v.email.required">Email is required.</span>
                      <span v-if="!$v.email.email">Please enter valid email.</span>
                    </div>
                  </div>

                  <div class="mt-2 mt-md-4 text-center">
                    <button class="btn btn-primary w-md waves-effect waves-light customs-bg-color" type="submit">SEND INSTRUCTIONS
                    </button>
                  </div>
                </form>
              </div>

              <div class="mt-3 mt-md-4 text-center position-absolute fixed-bottom">
                <div style="border-bottom: 1px solid #ddd "></div>
                <p class="mt-3 mt-md-4 text-center">© 2023 WORKANY. Crafted by <a href="https://w3engineers.com/" class="text-primary" target="_blank">W3
                  Engineers Ltd</a>.</p>
              </div>

            </div>
          </div>
          <div class="col-lg-7 col-xl-8 d-none d-lg-block">
            <div class="min-vh-100 py-5 d-flex align-items-center">
              <div class="w-100">
                <h4 class="font-size-28 text-center px-3">Did you really forgot your workspace?</h4>
                <p class="text-center px-3">Don't worry, there is a always a option to get your workspace. Lets have a
                  try.</p>
                <div class="d-flex justify-content-center">
                  <img src="@/assets/images/bg/forgot_password.jpg" height="100%" width="55%" alt="logo"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customs-bg-color{
  background-color: #4B1FE5 !important;
}
</style>
